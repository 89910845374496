import { defineStore } from "pinia";
import { CartItem } from "@/store/datatypes";
import { useToastStore } from "./toast";

export const useCartStore = defineStore({
  id: "cart",
  state: () => ({
    items: [] as CartItem[],
  }),
  getters: {
    subtotal(state) {
      let subtotal = 0;
      state.items.forEach((ele) => {
        subtotal += ele.price * ele.quantity;
      });
      return subtotal;
    },
  },
  actions: {
    addItemToCart(item: CartItem) {
      const toast = useToastStore();
      toast.addNewToast("Successfully added cart item");
      this.items.push(item);
    },
    removeItemFromCart(item: CartItem) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
  },
});
