<template>
  <div class="container m-4">
    <div class="card">
      <div class="card-body">
        <div class="mb-3 col-10">
          <label for="headlineInput" class="form-label"
            >Home Page Headline</label
          >
          <input
            type="text"
            class="form-control"
            id="headlineInput"
            v-model="homePageHeadline"
            placeholder="Welcome to my site"
          />
        </div>
        <div class="mb-3 col-10">
          <label for="homePageDescription" class="form-label">
            Home Page Header Description
          </label>
          <textarea
            v-model="homePageDescription"
            class="form-control"
            id="homePageDescription"
          >
          </textarea>
        </div>
        <div class="mb-3 col-5">
          <label for="typeSelector" class="form-label">
            What is the site type?
          </label>
          <select class="form-select" aria-label="Select Type" v-model="type">
            <option selected value="category">Category</option>
            <option value="source">Source</option>
          </select>
        </div>
        <div class="mb-3 col-5" v-if="type === 'category'">
          <label for="productSelector" class="form-label">
            Select Products</label
          >
          <select
            class="form-select"
            aria-label="Select Products"
            v-model="productKey"
            :disabled="isLoading"
          >
            <option selected>Open this menu to select products</option>
            <option v-for="category in categories" :key="category">
              {{ category }}
            </option>
          </select>
          <div class="clearfix" v-if="isLoading">
            <div class="spinner-border text-info float-end" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="mb-3 col-5" v-if="type === 'source'">
          <label for="productSelector" class="form-label"> Select Source</label>
          <select
            class="form-select"
            aria-label="Select Products"
            v-model="productKey"
            :disabled="isLoading"
          >
            <option selected>Open this menu to select a source</option>
            <option v-for="source in sources" :key="source">
              {{ source }}
            </option>
          </select>
          <div class="clearfix" v-if="isLoading">
            <div class="spinner-border text-info float-end" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="brandNameInput" class="form-label">Brand Name</label>
          <input
            type="text"
            class="form-control"
            id="brandNameInput"
            v-model="brandName"
            placeholder="Acme Products"
          />
        </div>
        <div class="mb-3 col-3">
          <label for="brandPartCodeInput" class="form-label"
            >Part Number Suffix</label
          >
          <input
            type="text"
            class="form-control"
            id="brandPartCodeInput"
            v-model="brandPartCode"
            placeholder="ACM"
            maxlength="3"
          />
        </div>
        <div class="mb-3 col-2">
          <label for="markupInput" class="form-label">Markup %</label>
          <input
            type="number"
            class="form-control"
            id="markupInput"
            v-model="markup"
          />
        </div>
        <button class="btn btn-primary" @click="createSite">Create Site</button>
        <button class="btn btn-danger" @click="resetSite">Reset Site</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useProductStore } from "@/store/product";
import { useSiteStore } from "@/store/site";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const product = useProductStore();
    const site = useSiteStore();
    const router = useRouter();

    const homePageHeadline = ref(site.homePageHeadline);
    const homePageDescription = ref(site.homePageDescription);
    const brandPartCode = ref(site.brandPartCode);
    const brandName = ref(site.brandName);
    const productKey = ref(site.productKey);
    const type = ref(site.type);
    const markup = ref(2.2);
    const isLoading = ref(true);
    // const categories = product.fetchCategorysAndBrands();
    const categories = computed(() => product.categories);
    const sources = computed(() => product.sources);

    function resetSite() {
      site.$reset();
      localStorage.removeItem("site");
    }

    function createSite() {
      console.log("Making the site");
      if (
        homePageHeadline.value &&
        homePageDescription.value &&
        brandPartCode.value &&
        brandName.value &&
        markup.value &&
        type.value &&
        productKey.value
      ) {
        // submit
        site.setData(
          homePageHeadline.value,
          homePageDescription.value,
          brandPartCode.value,
          markup.value / 100,
          brandName.value,
          type.value,
          productKey.value,
        );
        router.push("/");
      } else {
        alert("All Fields are required!");
      }
    }

    product
      .fetchCategorysAndBrands()
      .then((response) => {
        console.log("Categories have been loaded");
        isLoading.value = false;
      })
      .catch((error) => {
        console.log("There was a problem fetching the categories");
      });

    return {
      homePageHeadline,
      homePageDescription,
      brandPartCode,
      brandName,
      markup,
      categories,
      productKey,
      type,
      sources,
      createSite,
      resetSite,
      isLoading,
    };
  },
});
</script>
