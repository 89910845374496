
import { defineComponent, PropType, ref, computed } from "vue";
import { Product, CartItem } from "@/store/datatypes";
import { useProductStore } from "@/store/product";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const site = useSiteStore();
    const products = useProductStore();
    const brand = site.brandPartCode;
    const markup = site.markup;
    const cart = useCartStore();

    const product = computed(() => {
      const result = products.getProductById(props.id);
      return result;
    });

    const adjustedPrice = computed(() => {
      if (product.value) {
        return (product.value.friendly_price * (1 + markup)).toFixed(2);
      } else {
        return (0).toFixed(2);
      }
    });

    const purchaseQuantity = ref(1);
    function addToCart() {
      if (product.value) {
        console.log("I am adding to cart");
        const item = {
          product: product.value,
          price: Number(adjustedPrice.value),
          quantity: purchaseQuantity.value,
        };
        cart.addItemToCart(item);
      }
    }

    function adjustQuantity(qty: number) {
      purchaseQuantity.value = qty;
    }

    return {
      addToCart,
      adjustQuantity,
      product,
      brand,
      markup,
      purchaseQuantity,
      adjustedPrice,
    };
  },
});
