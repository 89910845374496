<template>
  <div class="dropstart nav-item ms-auto">
    <a
      class="btn btn-success dropdown-toggle"
      href="#"
      role="button"
      id="cartMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="bi-cart2"></i> Cart
      <span class="badge bg-secondary">{{ cartCount }}</span>
    </a>
    <ul class="dropdown-menu" style="width: 300px">
      <li class="dropdown-item-text">My Cart</li>
      <div v-if="cartItems.length > 0">
        <li v-for="item in cartItems" :key="item.product" class="dropdown-item">
          {{ item.product.name }} x {{ item.quantity }}
          <span class="float-end">
            ${{ (item.price * item.quantity).toFixed(2) }}
          </span>
        </li>
      </div>
      <li v-else class="dropdown-item text-info">Your cart is empty</li>
      <li class="dropdown-divider"></li>
      <li class="dropdown-item fw-bold">
        Total: ${{ cartTotal.toFixed(2) }}

        <button class="btn btn-sm btn-primary float-end" @click="checkout()">
          Check Out
        </button>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useCartStore } from "@/store/cart";
import router from "@/router/index";
export default defineComponent({
  setup() {
    const cart = useCartStore();

    const cartItems = computed(() => cart.items);
    const cartCount = computed(() => cartItems.value.length);
    const cartTotal = computed(() => cart.subtotal);

    function checkout() {
      console.log("Let's check out");
      router.push("/checkout");
    }

    return { cartItems, cartCount, cartTotal, checkout };
  },
});
</script>
