<template>
  <Nav />
  <!-- <button class="btn btn-primary" @click="makeToast">Toast</button> -->
  <router-view />
  <div class="toast-container position-absolute top-0 end-0 p-3">
    <div
      class="toast bg-success bg-gradient text-white"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      id="myToast"
    >
      <div class="toast-body">
        Item added to cart!
        <button
          type="button"
          class="btn-close btn-close-white float-end"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>

    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">Bootstrap</strong>
        <small class="text-muted">2 seconds ago</small>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div class="toast-body">Heads up, toasts will stack automatically</div>
    </div>
  </div>

  <Footer />
</template>
<script lang="ts">
import { defineComponent, watch } from "vue";
import Nav from "@/views/layout/Nav.vue";
import Footer from "@/views/layout/Footer.vue";
import { useSiteStore } from "@/store/site";
import { Toast } from "bootstrap";
import { useToastStore } from "@/store/toast";
export default defineComponent({
  components: {
    Nav,
    Footer,
  },
  setup() {
    const site = useSiteStore();
    const toastStore = useToastStore();

    const myToast = document.getElementById("myToast");
    if (myToast) {
      const toast = new Toast(myToast);
      toast.show();
    }

    function makeToast() {
      console.log("Making toast");
      const myToast = document.getElementById("myToast");
      if (myToast) {
        console.log("Toast is not null");
        const toast = new Toast(myToast);
        console.log(toast);
        console.log(toast);
        toast.show();
      }
    }

    if (localStorage.getItem("site")) {
      const data = localStorage.getItem("site");
      console.log(data);
      site.setFromLocalStorage();
    }

    watch(toastStore.$state, (currentValue, oldValue) => {
      console.log("there should be a new toast now");
      const myToast = document.getElementById("myToast");
      if (myToast) {
        const toast = new Toast(myToast);
        toast.show();
      }
    });

    watch(
      () => site.$state,
      () => {
        localStorage.setItem("site", JSON.stringify(site.$state));
      },
      { deep: true },
    );
    return { makeToast };
  },
});
</script>
