
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    headline: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return { props };
  },
});
