
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    console.log("The alert is mounted");
  },
});
