
import { defineComponent, ref, computed } from "vue";
import { useProductStore } from "@/store/product";
import { useSiteStore } from "@/store/site";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const product = useProductStore();
    const site = useSiteStore();
    const router = useRouter();

    const homePageHeadline = ref(site.homePageHeadline);
    const homePageDescription = ref(site.homePageDescription);
    const brandPartCode = ref(site.brandPartCode);
    const brandName = ref(site.brandName);
    const productKey = ref(site.productKey);
    const type = ref(site.type);
    const markup = ref(2.2);
    const isLoading = ref(true);
    // const categories = product.fetchCategorysAndBrands();
    const categories = computed(() => product.categories);
    const sources = computed(() => product.sources);

    function resetSite() {
      site.$reset();
      localStorage.removeItem("site");
    }

    function createSite() {
      console.log("Making the site");
      if (
        homePageHeadline.value &&
        homePageDescription.value &&
        brandPartCode.value &&
        brandName.value &&
        markup.value &&
        type.value &&
        productKey.value
      ) {
        // submit
        site.setData(
          homePageHeadline.value,
          homePageDescription.value,
          brandPartCode.value,
          markup.value / 100,
          brandName.value,
          type.value,
          productKey.value,
        );
        router.push("/");
      } else {
        alert("All Fields are required!");
      }
    }

    product
      .fetchCategorysAndBrands()
      .then((response) => {
        console.log("Categories have been loaded");
        isLoading.value = false;
      })
      .catch((error) => {
        console.log("There was a problem fetching the categories");
      });

    return {
      homePageHeadline,
      homePageDescription,
      brandPartCode,
      brandName,
      markup,
      categories,
      productKey,
      type,
      sources,
      createSite,
      resetSite,
      isLoading,
    };
  },
});
