import { defineStore } from "pinia";

export const useSiteStore = defineStore({
  id: "site",
  state: () => ({
    homePageHeadline: "",
    homePageDescription: "",
    brandPartCode: "",
    brandName: "",
    markup: 0.022,
    type: "category",
    productKey: "",
  }),
  actions: {
    setData(
      headline: string,
      description: string,
      code: string,
      markup: number,
      brand: string,
      type: string,
      productKey: string,
    ) {
      this.homePageHeadline = headline;
      this.homePageDescription = description;
      this.brandPartCode = code;
      this.markup = markup;
      this.brandName = brand;
      this.type = type;
      this.productKey = productKey;
    },
    setFromLocalStorage() {
      const data = localStorage.getItem("site");
      console.log("in the store");

      if (data) {
        console.log("Got the data");
        const json = JSON.parse(data);
        console.log(json);
        this.homePageHeadline = json.homePageHeadline;
        this.homePageDescription = json.homePageDescription;
        this.brandPartCode = json.brandPartCode;
        this.markup = json.markup;
        this.brandName = json.brandName;
        this.type = json.type;
        this.productKey = json.productKey;
      }
    },
  },
});
