<template>
  <div class="page-title-overlap bg-primary pt-4">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
      <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol
            class="
              breadcrumb breadcrumb-light
              flex-lg-nowrap
              justify-content-center justify-content-lg-start
            "
          >
            <li class="breadcrumb-item">
              <a class="text-nowrap" href="index.html"
                ><i class="ci-home"></i>Home</a
              >
            </li>
            <li class="breadcrumb-item text-nowrap">
              <a href="marketplace-category.html">Market</a>
            </li>
            <li class="breadcrumb-item text-nowrap active" aria-current="page">
              Single Item
            </li>
          </ol>
        </nav>
      </div>
      <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
        <h1 class="h3 text-light mb-0">
          {{ product.name + "-" + brand }} / {{ product.category }}
        </h1>
      </div>
    </div>
  </div>
  <section class="container mb-3 pb-3">
    <div class="bg-light shadow-lg rounded-3 overflow-hidden">
      <div class="row">
        <section class="col-lg-8 pt-2 pt-lg-4 pb-4 mb-lg-3">
          <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <img class="img-fluid" src="https://place-hold.it/525" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <img
                class="img-fluid"
                style="margin-right: 10px"
                src="https://place-hold.it/225"
              />
              <img class="img-fluid" src="https://place-hold.it/225" />
            </div>
          </div>
        </section>
        <aside class="col-lg-4 ps-xl-5">
          <hr class="d-lg-none" />
          <div class="bg-white h-100 p-4 ms-auto border-start">
            <div class="px-lg-2">
              <div class="accordion accordion-flush" id="licenses">
                <div class="accordion-item border-bottom">
                  <div
                    class="
                      accordion-header
                      d-flex
                      justify-content-between
                      align-items-center
                      py-3
                    "
                  >
                    <div
                      class="form-check"
                      data-bs-toggle="collapse"
                      data-bs-target="#standard-license"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="license"
                        id="license-std"
                        checked=""
                        @click="adjustQuantity(1)"
                      />
                      <label
                        class="form-check-label fw-medium text-dark"
                        for="license-std"
                        >Single Item</label
                      >
                    </div>
                    <h5 class="mb-0 text-accent fw-normal">
                      ${{ (product.friendly_price * (1 + markup)).toFixed(2) }}
                    </h5>
                  </div>
                  <div
                    class="accordion-collapse collapse show"
                    id="standard-license"
                    data-bs-parent="#licenses"
                  >
                    <div class="accordion-body py-0 pb-2">
                      <ul class="list-unstyled fs-sm">
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">Quality verified</span>
                        </li>
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">Verified OEM</span>
                        </li>
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">In Stock</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div
                    class="
                      accordion-header
                      d-flex
                      justify-content-between
                      align-items-center
                      py-3
                    "
                  >
                    <div
                      class="form-check"
                      data-bs-toggle="collapse"
                      data-bs-target="#extended-license"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="license"
                        @click="adjustQuantity(10)"
                        id="license-ext"
                      />
                      <label
                        class="form-check-label fw-medium text-dark"
                        for="license-ext"
                        >Bulk Purchase 10+</label
                      >
                    </div>
                    <h5 class="mb-0 text-accent fw-normal">
                      ${{
                        (product.friendly_price * (1 + markup) * 0.97).toFixed(
                          2,
                        )
                      }}
                    </h5>
                  </div>
                  <div
                    class="accordion-collapse collapse border-0"
                    id="extended-license"
                    data-bs-parent="#licenses"
                  >
                    <div class="accordion-body py-0 pb-2">
                      <ul class="list-unstyled fs-sm">
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">Quality verified</span>
                        </li>
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">Ship to Single Location</span>
                        </li>
                        <li class="d-flex align-items-center">
                          <i class="ci-check-circle text-success me-1"></i
                          ><span class="fs-ms">Not for Resale</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mb-3">
                <label for="qty" class="form-label">Qty</label>
                <input
                  type="text"
                  class="form-control"
                  id="qty"
                  v-model="purchaseQuantity"
                />
              </div>
              <button
                class="btn btn-primary btn-shadow d-block w-100 mt-4"
                type="button"
                @click="addToCart"
              >
                <i class="ci-cart fs-lg me-2"></i>Add to Cart
              </button>

              <div class="bg-secondary rounded p-3 mt-4 mb-2">
                <i
                  class="ci-download h5 text-muted align-middle mb-0 mt-n1 me-2"
                ></i
                ><span class="d-inline-block h6 mb-0 me-1">{{
                  product.stock
                }}</span
                ><span class="fs-sm">Remaining</span>
              </div>
              <div class="bg-secondary rounded p-3 mb-4">
                <div class="star-rating">
                  <i class="star-rating-icon ci-star-filled active"></i
                  ><i class="star-rating-icon ci-star-filled active"></i
                  ><i class="star-rating-icon ci-star-filled active"></i
                  ><i class="star-rating-icon ci-star-filled active"></i
                  ><i class="star-rating-icon ci-star"></i>
                </div>
                <span class="fs-ms ms-2">4.1/5</span>
                <div class="fs-ms text-muted">based on 74 reviews</div>
              </div>
              <ul class="list-unstyled fs-sm">
                <li
                  class="d-flex justify-content-between mb-3 pb-3 border-bottom"
                >
                  <span class="text-dark fw-medium">Last update</span
                  ><span class="text-muted">Today</span>
                </li>
                <li
                  class="d-flex justify-content-between mb-3 pb-3 border-bottom"
                >
                  <span class="text-dark fw-medium">Released</span
                  ><span class="text-muted">{{ product.created_on }}</span>
                </li>
                <li
                  class="d-flex justify-content-between mb-3 pb-3 border-bottom"
                >
                  <span class="text-dark fw-medium">Category</span
                  ><a class="product-meta" href="#">{{ product.category }}</a>
                </li>
                <li
                  class="d-flex justify-content-between mb-3 pb-3 border-bottom"
                >
                  <span class="text-dark fw-medium">Compatible with</span
                  ><span class="text-muted">Chrome OS</span>
                </li>
                <li
                  class="d-flex justify-content-between mb-3 pb-3 border-bottom"
                >
                  <span class="text-dark fw-medium">Warranty</span
                  ><span class="text-muted">30 days</span>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </section>
  <section class="container mb-4 mb-lg-5">
    <!-- Nav tabs-->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link p-4 active"
          href="#details"
          data-bs-toggle="tab"
          role="tab"
          aria-selected="true"
          >Product details</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link p-4"
          href="#reviews"
          data-bs-toggle="tab"
          role="tab"
          aria-selected="false"
          >Reviews</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link p-4" href="#comments" data-bs-toggle="tab" role="tab"
          >Comments</a
        >
      </li>
    </ul>
    <div class="tab-content pt-2">
      <!-- Product details tab-->
      <div class="tab-pane fade active show" id="details" role="tabpanel">
        <div class="row">
          <div class="col-lg-8">
            <p class="fs-md m-4">
              {{ product.description }}
            </p>
            <!--  -->
          </div>
        </div>
      </div>
      <!-- Reviews tab-->
      <div class="tab-pane fade" id="reviews" role="tabpanel">
        <!-- Reviews-->
        <div class="row pt-2 pb-3">
          <div class="col-lg-4 col-md-5">
            <h3 class="h4 mb-4">74 Reviews</h3>
            <div class="star-rating me-2">
              <i class="ci-star-filled fs-sm text-accent me-1"></i
              ><i class="ci-star-filled fs-sm text-accent me-1"></i
              ><i class="ci-star-filled fs-sm text-accent me-1"></i
              ><i class="ci-star-filled fs-sm text-accent me-1"></i
              ><i class="ci-star fs-sm text-muted me-1"></i>
            </div>
            <span class="d-inline-block align-middle">4.1 Overall rating</span>
            <p class="pt-3 fs-sm text-muted">
              58 out of 74 (77%)<br />Customers recommended this product
            </p>
          </div>
          <div class="col-lg-8 col-md-7">
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap me-3">
                <span class="d-inline-block align-middle text-muted">5</span
                ><i class="ci-star-filled fs-xs ms-1"></i>
              </div>
              <div class="w-100">
                <div class="progress" style="height: 4px">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 60%"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <span class="text-muted ms-3">43</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap me-3">
                <span class="d-inline-block align-middle text-muted">4</span
                ><i class="ci-star-filled fs-xs ms-1"></i>
              </div>
              <div class="w-100">
                <div class="progress" style="height: 4px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 27%; background-color: #a7e453"
                    aria-valuenow="27"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <span class="text-muted ms-3">16</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap me-3">
                <span class="d-inline-block align-middle text-muted">3</span
                ><i class="ci-star-filled fs-xs ms-1"></i>
              </div>
              <div class="w-100">
                <div class="progress" style="height: 4px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 17%; background-color: #ffda75"
                    aria-valuenow="17"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <span class="text-muted ms-3">9</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap me-3">
                <span class="d-inline-block align-middle text-muted">2</span
                ><i class="ci-star-filled fs-xs ms-1"></i>
              </div>
              <div class="w-100">
                <div class="progress" style="height: 4px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 9%; background-color: #fea569"
                    aria-valuenow="9"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <span class="text-muted ms-3">4</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="text-nowrap me-3">
                <span class="d-inline-block align-middle text-muted">1</span
                ><i class="ci-star-filled fs-xs ms-1"></i>
              </div>
              <div class="w-100">
                <div class="progress" style="height: 4px">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 4%"
                    aria-valuenow="4"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <span class="text-muted ms-3">2</span>
            </div>
          </div>
        </div>
        <hr class="mt-4 mb-3" />
        <div class="row py-4">
          <!-- Reviews list-->
          <div class="col-md-7">
            <div class="d-flex justify-content-end pb-4">
              <div class="d-flex align-items-center flex-nowrap">
                <label
                  class="fs-sm text-muted text-nowrap me-2 d-none d-sm-block"
                  for="sort-reviews"
                  >Sort by:</label
                >
                <select class="form-select form-select-sm" id="sort-reviews">
                  <option>Newest</option>
                  <option>Oldest</option>
                  <option>Popular</option>
                  <option>High rating</option>
                  <option>Low rating</option>
                </select>
              </div>
            </div>
            <!-- Review-->
            <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="d-flex align-items-center me-4 pe-2">
                  <img
                    class="rounded-circle"
                    src="img/shop/reviews/01.jpg"
                    width="50"
                    alt="Rafael Marquez"
                  />
                  <div class="ps-3">
                    <h6 class="fs-sm mb-0">Rafael Marquez</h6>
                    <span class="fs-ms text-muted">June 28, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating">
                    <i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star"></i>
                  </div>
                  <div class="fs-ms text-muted">
                    83% of users found this review helpful
                  </div>
                </div>
              </div>
              <p class="fs-md mb-2">
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus id quod maxime placeat facere possimus,
                omnis voluptas assumenda est...
              </p>
              <ul class="list-unstyled fs-ms pt-1">
                <li class="mb-1">
                  <span class="fw-medium">Pros:&nbsp;</span>Consequuntur magni,
                  voluptatem sequi, tempora
                </li>
                <li class="mb-1">
                  <span class="fw-medium">Cons:&nbsp;</span>Architecto beatae,
                  quis autem
                </li>
              </ul>
              <div class="text-nowrap">
                <button class="btn-like" type="button">15</button>
                <button class="btn-dislike" type="button">3</button>
              </div>
            </div>
            <!-- Review-->
            <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="d-flex align-items-center me-4 pe-2">
                  <img
                    class="rounded-circle"
                    src="img/shop/reviews/02.jpg"
                    width="50"
                    alt="Barbara Palson"
                  />
                  <div class="ps-3">
                    <h6 class="fs-sm mb-0">Barbara Palson</h6>
                    <span class="fs-ms text-muted">May 17, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating">
                    <i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i>
                  </div>
                  <div class="fs-ms text-muted">
                    99% of users found this review helpful
                  </div>
                </div>
              </div>
              <p class="fs-md mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <ul class="list-unstyled fs-ms pt-1">
                <li class="mb-1">
                  <span class="fw-medium">Pros:&nbsp;</span>Consequuntur magni,
                  voluptatem sequi, tempora
                </li>
                <li class="mb-1">
                  <span class="fw-medium">Cons:&nbsp;</span>Architecto beatae,
                  quis autem
                </li>
              </ul>
              <div class="text-nowrap">
                <button class="btn-like" type="button">34</button>
                <button class="btn-dislike" type="button">1</button>
              </div>
            </div>
            <!-- Review-->
            <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="d-flex align-items-center me-4 pe-2">
                  <img
                    class="rounded-circle"
                    src="img/shop/reviews/03.jpg"
                    width="50"
                    alt="Daniel Adams"
                  />
                  <div class="ps-3">
                    <h6 class="fs-sm mb-0">Daniel Adams</h6>
                    <span class="fs-ms text-muted">May 8, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating">
                    <i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-filled active"></i
                    ><i class="star-rating-icon ci-star-half active"></i
                    ><i class="star-rating-icon ci-star"></i>
                  </div>
                  <div class="fs-ms text-muted">
                    75% of users found this review helpful
                  </div>
                </div>
              </div>
              <p class="fs-md mb-2">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.
              </p>
              <ul class="list-unstyled fs-ms pt-1">
                <li class="mb-1">
                  <span class="fw-medium">Pros:&nbsp;</span>Consequuntur magni,
                  voluptatem sequi
                </li>
                <li class="mb-1">
                  <span class="fw-medium">Cons:&nbsp;</span>Architecto beatae,
                  quis autem, voluptatem sequ
                </li>
              </ul>
              <div class="text-nowrap">
                <button class="btn-like" type="button">26</button>
                <button class="btn-dislike" type="button">9</button>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-outline-accent" type="button">
                <i class="ci-reload me-2"></i>Load more reviews
              </button>
            </div>
          </div>
          <!-- Leave review form-->
          <div class="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
            <div class="bg-secondary py-grid-gutter px-grid-gutter rounded-3">
              <h3 class="h4 pb-2">Write a review</h3>
              <form class="needs-validation" method="post" novalidate="">
                <div class="mb-3">
                  <label class="form-label" for="review-name"
                    >Your name<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    required=""
                    id="review-name"
                  />
                  <div class="invalid-feedback">Please enter your name!</div>
                  <small class="form-text text-muted"
                    >Will be displayed on the comment.</small
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label" for="review-email"
                    >Your email<span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="email"
                    required=""
                    id="review-email"
                  />
                  <div class="invalid-feedback">
                    Please provide valid email address!
                  </div>
                  <small class="form-text text-muted"
                    >Authentication only - we won't spam you.</small
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label" for="review-rating"
                    >Rating<span class="text-danger">*</span></label
                  >
                  <select class="form-select" required="" id="review-rating">
                    <option value="">Choose rating</option>
                    <option value="5">5 stars</option>
                    <option value="4">4 stars</option>
                    <option value="3">3 stars</option>
                    <option value="2">2 stars</option>
                    <option value="1">1 star</option>
                  </select>
                  <div class="invalid-feedback">Please choose rating!</div>
                </div>
                <div class="mb-3">
                  <label class="form-label" for="review-text"
                    >Review<span class="text-danger">*</span></label
                  >
                  <textarea
                    class="form-control"
                    rows="6"
                    required=""
                    id="review-text"
                  ></textarea>
                  <div class="invalid-feedback">Please write a review!</div>
                  <small class="form-text text-muted"
                    >Your review must be at least 50 characters.</small
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label" for="review-pros">Pros</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Separated by commas"
                    id="review-pros"
                  ></textarea>
                </div>
                <div class="mb-3 mb-4">
                  <label class="form-label" for="review-cons">Cons</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Separated by commas"
                    id="review-cons"
                  ></textarea>
                </div>
                <button
                  class="btn btn-primary btn-shadow d-block w-100"
                  type="submit"
                >
                  Submit a Review
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Comments tab-->
      <div class="tab-pane fade" id="comments" role="tabpanel">
        <div class="row">
          <div class="col-lg-8">
            <!-- comment-->
            <div class="d-flex align-items-start py-4 border-bottom">
              <img
                class="rounded-circle"
                src="img/testimonials/04.jpg"
                width="50"
                alt="Laura Willson"
              />
              <div class="ps-3">
                <div
                  class="d-flex justify-content-between align-items-center mb-2"
                >
                  <h6 class="fs-md mb-0">Laura Willson</h6>
                  <a class="nav-link-style fs-sm fw-medium" href="#"
                    ><i class="ci-reply me-2"></i>Reply</a
                  >
                </div>
                <p class="fs-md mb-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat cupidatat non
                  proident, sunt in culpa qui.
                </p>
                <span class="fs-ms text-muted"
                  ><i class="ci-time align-middle me-2"></i>Sep 7, 2019</span
                >
                <!-- comment reply-->
                <div class="d-flex align-items-start border-top pt-4 mt-4">
                  <img
                    class="rounded-circle"
                    src="img/testimonials/01.jpg"
                    width="50"
                    alt="Sara Palson"
                  />
                  <div class="ps-3">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-2
                      "
                    >
                      <h6 class="fs-md mb-0">Sara Palson</h6>
                    </div>
                    <p class="fs-md mb-1">
                      Egestas sed sed risus pretium quam vulputate dignissim. A
                      diam sollicitudin tempor id eu nisl. Ut porttitor leo a
                      diam. Bibendum at varius vel pharetra vel turpis nunc.
                    </p>
                    <span class="fs-ms text-muted"
                      ><i class="ci-time align-middle me-2"></i>Sep 13,
                      2019</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- comment-->
            <div class="d-flex align-items-start py-4">
              <img
                class="rounded-circle"
                src="img/testimonials/02.jpg"
                width="50"
                alt="Benjamin Miller"
              />
              <div class="ps-3">
                <div
                  class="d-flex justify-content-between align-items-center mb-2"
                >
                  <h6 class="fs-md mb-0">Benjamin Miller</h6>
                  <a class="nav-link-style fs-sm fw-medium" href="#"
                    ><i class="ci-reply me-2"></i>Reply</a
                  >
                </div>
                <p class="fs-md mb-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat cupidatat non
                  proident, sunt in culpa qui.
                </p>
                <span class="fs-ms text-muted"
                  ><i class="ci-time align-middle me-2"></i>Aug 15, 2019</span
                >
              </div>
            </div>
            <!-- Post comment form-->
            <div class="card border-0 shadow my-2">
              <div class="card-body">
                <div class="d-flex align-items-start">
                  <img
                    class="rounded-circle border p-2"
                    src="img/marketplace/account/avatar-sm.png"
                    width="50"
                    alt="Createx Studio"
                  />
                  <form class="needs-validation w-100 ms-3" novalidate="">
                    <div class="mb-3">
                      <textarea
                        class="form-control"
                        rows="4"
                        placeholder="Write comment..."
                        required=""
                      ></textarea>
                      <div class="invalid-feedback">
                        Please write your comment.
                      </div>
                    </div>
                    <button class="btn btn-primary btn-sm" type="submit">
                      Post comment
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Side bar with categories and other info -->

  <!-- main section with product details -->

  <!-- right sidebar with a contact us thingy or something -->
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import { Product, CartItem } from "@/store/datatypes";
import { useProductStore } from "@/store/product";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const site = useSiteStore();
    const products = useProductStore();
    const brand = site.brandPartCode;
    const markup = site.markup;
    const cart = useCartStore();

    const product = computed(() => {
      const result = products.getProductById(props.id);
      return result;
    });

    const adjustedPrice = computed(() => {
      if (product.value) {
        return (product.value.friendly_price * (1 + markup)).toFixed(2);
      } else {
        return (0).toFixed(2);
      }
    });

    const purchaseQuantity = ref(1);
    function addToCart() {
      if (product.value) {
        console.log("I am adding to cart");
        const item = {
          product: product.value,
          price: Number(adjustedPrice.value),
          quantity: purchaseQuantity.value,
        };
        cart.addItemToCart(item);
      }
    }

    function adjustQuantity(qty: number) {
      purchaseQuantity.value = qty;
    }

    return {
      addToCart,
      adjustQuantity,
      product,
      brand,
      markup,
      purchaseQuantity,
      adjustedPrice,
    };
  },
});
</script>
