<template>
  <ul class="cart_list">
    <li class="cart_item clearfix" v-for="item in items" :key="item">
      <!-- <div class="cart_item_image">
        <img src="https://i.imgur.com/qqBRWD5.jpg" alt="" />
      </div> -->
      <div
        class="
          cart_item_info
          d-flex
          flex-md-row flex-column
          justify-content-between
        "
      >
        <div class="cart_item_name cart_info_col">
          <div class="cart_item_title">Name</div>
          <div class="cart_item_text">{{ item.product.name }}</div>
        </div>

        <div class="cart_item_quantity cart_info_col text-center">
          <div class="cart_item_title">Quantity</div>
          <div class="cart_item_text">{{ item.quantity }}</div>
        </div>
        <div class="cart_item_price cart_info_col text-center">
          <div class="cart_item_title">Price</div>
          <div class="cart_item_text">${{ item.price }}</div>
        </div>
        <div class="cart_item_total cart_info_col text-center">
          <div class="cart_item_title">Total</div>
          <div class="cart_item_text">
            ${{ (item.price * item.quantity).toFixed(2) }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CartItem } from "@/store/datatypes";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<CartItem[]>,
      required: true,
    },
  },
  setup(props) {
    console.log("Here are the items");
    console.log(props.items);
  },
});
</script>
