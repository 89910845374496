<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary bg-gradient">
    <div class="container-fluid d-flex">
      <a class="navbar-brand" href="#">{{ brand }}</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>

          <li class="nav-item">
            <a
              class="nav-link disabled"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              >Disabled</a
            >
          </li>
        </ul>
        <CartButton />
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";
import CartButton from "@/components/cart/NavbarButton.vue";
export default defineComponent({
  components: {
    CartButton,
  },
  setup() {
    const user = ref(null);
    const site = useSiteStore();
    const cart = useCartStore();
    const brand = computed(() => site.brandName);
    const cartItems = computed(() => cart.items);
    const cartCount = computed(() => cartItems.value.length);
    const cartTotal = computed(() => cart.subtotal);
    console.log(brand);

    return { brand, user, cartItems, cartCount, cartTotal };
  },
});
</script>
