
import { defineComponent, ref, computed } from "vue";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";
import CartButton from "@/components/cart/NavbarButton.vue";
export default defineComponent({
  components: {
    CartButton,
  },
  setup() {
    const user = ref(null);
    const site = useSiteStore();
    const cart = useCartStore();
    const brand = computed(() => site.brandName);
    const cartItems = computed(() => cart.items);
    const cartCount = computed(() => cartItems.value.length);
    const cartTotal = computed(() => cart.subtotal);
    console.log(brand);

    return { brand, user, cartItems, cartCount, cartTotal };
  },
});
