
import { defineComponent, PropType } from "vue";
import { CartItem } from "@/store/datatypes";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<CartItem[]>,
      required: true,
    },
  },
  setup(props) {
    console.log("Here are the items");
    console.log(props.items);
  },
});
