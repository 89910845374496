import { defineStore } from "pinia";

export const useToastStore = defineStore({
  id: "toasts",
  state: () => ({
    messages: [] as string[],
    haveToasts: false,
  }),
  getters: {
    pendingMessages(state) {
      return state.messages;
    },
  },
  actions: {
    addNewToast(message: string) {
      console.log("Adding a new toast in the toast store");
      console.log(message);
      this.messages.push(message);
      this.haveToasts = true;
    },
    resolveToast(message: string) {
      this.messages.splice(this.messages.indexOf(message), 1);
    },
  },
});
