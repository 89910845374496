
import { defineComponent, ref, computed } from "vue";
import { useProductStore } from "@/store/product";
import Product from "@/components/product/product_table/Product.vue";

export default defineComponent({
  components: {
    Product,
  },
  setup() {
    const products = useProductStore();
    products.fetchProducts();

    const activeProducts = computed(() => products.products);
    function nextPage() {
      products.getNextPage();
    }

    function prevPage() {
      products.getPrevPage();
    }

    return { activeProducts, products, nextPage, prevPage };
  },
});
