
import { defineComponent, ref, computed } from "vue";
import { useCartStore } from "@/store/cart";
import router from "@/router/index";
export default defineComponent({
  setup() {
    const cart = useCartStore();

    const cartItems = computed(() => cart.items);
    const cartCount = computed(() => cartItems.value.length);
    const cartTotal = computed(() => cart.subtotal);

    function checkout() {
      console.log("Let's check out");
      router.push("/checkout");
    }

    return { cartItems, cartCount, cartTotal, checkout };
  },
});
