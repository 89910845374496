<template>
  <div class="container">
    <section class="hljs-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="cart_container">
              <div class="cart_title">
                Shopping Cart
                <small> ({{ items.length }} item in your cart)</small>
              </div>
              <CheckoutTable :items="items" />
            </div>
            <div class="order_total">
              <div class="order_total_content text-md-end">
                <div class="order_total_title">Order Total:</div>
                <div class="order_total_amount">${{ total.toFixed(2) }}</div>
              </div>
            </div>
            <div class="float-end">
              <router-link
                class="btn btn-outline-primary m-2"
                to="/"
                tag="button"
              >
                Continue Shopping
              </router-link>
              <button
                class="btn btn-primary m-2"
                data-bs-toggle="modal"
                data-bs-target="#checkoutError"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <GeneralAlert
      message="Paypal has not authorized this domain"
      target="checkoutError"
    />
  </div>
</template>
<style>
ul {
  list-style: none;
  margin-bottom: 0px;
}

.cart_section {
  width: 100%;
  padding-top: 93px;
  padding-bottom: 111px;
}

.cart_title {
  font-size: 30px;
  font-weight: 500;
}

.cart_items {
  margin-top: 8px;
}

.cart_list {
  border: solid 1px #e8e8e8;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.cart_item {
  width: 100%;
  padding: 15px;
  padding-right: 46px;
}

.cart_item_image {
  width: 133px;
  height: 133px;
  float: left;
}

.cart_item_image img {
  max-width: 100%;
}

.cart_item_info {
  width: calc(100% - 133px);
  float: left;
  padding-top: 18px;
}

.cart_item_name {
  margin-left: 7.53%;
}

.cart_item_title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.cart_item_text {
  font-size: 18px;
  margin-top: 35px;
}

.cart_item_text span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 11px;
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -ms-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
}

.cart_item_price {
  text-align: right;
}

.cart_item_total {
  text-align: right;
}

.order_total {
  width: 100%;
  height: 60px;
  margin-top: 30px;
  border: solid 1px #e8e8e8;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  padding-right: 46px;
  padding-left: 15px;
  background-color: #fff;
}

.order_total_title {
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 60px;
}

.order_total_amount {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin-left: 26px;
  line-height: 60px;
}
</style>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useCartStore } from "@/store/cart";
import CheckoutTable from "@/components/cart/CheckoutTable.vue";
import GeneralAlert from "@/components/alerts/GeneralAlert.vue";

export default defineComponent({
  components: {
    CheckoutTable,
    GeneralAlert,
  },
  setup() {
    const cart = useCartStore();
    const items = computed(() => cart.items);
    const total = computed(() => cart.subtotal);

    return { items, total };
  },
});
</script>
