
import { defineComponent, watch } from "vue";
import Nav from "@/views/layout/Nav.vue";
import Footer from "@/views/layout/Footer.vue";
import { useSiteStore } from "@/store/site";
import { Toast } from "bootstrap";
import { useToastStore } from "@/store/toast";
export default defineComponent({
  components: {
    Nav,
    Footer,
  },
  setup() {
    const site = useSiteStore();
    const toastStore = useToastStore();

    const myToast = document.getElementById("myToast");
    if (myToast) {
      const toast = new Toast(myToast);
      toast.show();
    }

    function makeToast() {
      console.log("Making toast");
      const myToast = document.getElementById("myToast");
      if (myToast) {
        console.log("Toast is not null");
        const toast = new Toast(myToast);
        console.log(toast);
        console.log(toast);
        toast.show();
      }
    }

    if (localStorage.getItem("site")) {
      const data = localStorage.getItem("site");
      console.log(data);
      site.setFromLocalStorage();
    }

    watch(toastStore.$state, (currentValue, oldValue) => {
      console.log("there should be a new toast now");
      const myToast = document.getElementById("myToast");
      if (myToast) {
        const toast = new Toast(myToast);
        toast.show();
      }
    });

    watch(
      () => site.$state,
      () => {
        localStorage.setItem("site", JSON.stringify(site.$state));
      },
      { deep: true },
    );
    return { makeToast };
  },
});
