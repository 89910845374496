import { defineStore } from "pinia";
import { Product } from "./datatypes";
import { useSiteStore } from "./site";
import axios from "axios";
const BASE_URL = "https://api.channelmind.com/";

// export type RootState = {
//   products: Product[];
// };

export const useProductStore = defineStore({
  id: "products",
  state: () => ({
    products: [] as Product[],
    total: 0 as number,
    nextPage: "" as string,
    prevPage: "" as string,
    categories: [] as string[],
    sources: [] as string[],
  }),
  getters: {
    inventoryParams() {
      const site = useSiteStore();
      return { inventoryType: site.type, inventoryKey: site.productKey };
    },
  },
  actions: {
    addProduct(product: Product) {
      this.products.push(product);
    },
    getProductById(id: number) {
      return this.products.find((e: Product) => {
        console.log(`We are looking for ${id}`);

        if (Number(e.id) === Number(id)) {
          console.log("success!");
          return e;
        }
      });
    },
    async fetchProducts() {
      try {
        const params = this.inventoryParams;
        console.log("here are the inventory params");
        console.log(params);
        const response = await axios.get(
          `${BASE_URL}products/get_category/?${params.inventoryType}=${params.inventoryKey}`,
        );
        console.log(response.data);
        this.products = response.data.results;
        this.total = response.data.count;
        this.nextPage = response.data.next;
        this.prevPage = response.data.previous;
      } catch (e) {
        console.log("There was an error");
        console.log(e);
      }
    },
    async getNextPage() {
      try {
        const response = await axios.get(this.nextPage);
        console.log(response.data);
        this.products = response.data.results;
        this.total = response.data.count;
        this.nextPage = response.data.next;
        this.prevPage = response.data.previous;
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getPrevPage() {
      try {
        const response = await axios.get(this.prevPage);
        this.products = response.data.results;
        this.total = response.data.count;
        this.nextPage = response.data.next;
        this.prevPage = response.data.previous;
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCategorysAndBrands() {
      try {
        const response = await axios.get(`${BASE_URL}products/productsets/`);
        console.log(response.data);
        this.categories = response.data.categories;
        this.sources = response.data.sources;
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
