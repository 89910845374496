
// import { Options, Vue } from "vue-class-component";
import { defineComponent } from "vue";
import Hero from "@/components/homepage/Hero.vue";
import ProductList from "@/components/product/product_table/Main.vue";
import { useSiteStore } from "@/store/site";
export default defineComponent({
  components: {
    Hero,
    ProductList,
  },
  setup() {
    const site = useSiteStore();
    const title = site.homePageHeadline;
    const description = site.homePageDescription;

    return { title, description };
  },
});
