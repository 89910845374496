<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-2">
          <img class="card-img" src="https://place-hold.it/300" />
        </div>
        <div class="col">
          <h4>
            <router-link :to="`/product/${productData.id}`">{{
              productData.name + "-" + brand
            }}</router-link>
          </h4>
          <p>{{ productData.description }}</p>
        </div>
        <div class="col-2 justify-content-center">
          <h5>${{ (productData.friendly_price * (1 + markup)).toFixed(2) }}</h5>
          <button class="btn btn-success">Buy Now!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { Product, CartItem } from "@/store/datatypes";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";
export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const site = useSiteStore();
    const cart = useCartStore();
    const brand = site.brandPartCode;
    const markup = site.markup;
    const productData = computed((): Product => props.product);

    return { productData, brand, markup };
  },
});
</script>
