
import { defineComponent, PropType, computed } from "vue";
import { Product, CartItem } from "@/store/datatypes";
import { useSiteStore } from "@/store/site";
import { useCartStore } from "@/store/cart";
export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props) {
    const site = useSiteStore();
    const cart = useCartStore();
    const brand = site.brandPartCode;
    const markup = site.markup;
    const productData = computed((): Product => props.product);

    return { productData, brand, markup };
  },
});
