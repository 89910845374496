<template>
  <h1>Products</h1>
  Total Products: {{ products.total }}
  <a class="link-primary" @click="prevPage" v-if="products.prevPage">
    Previous</a
  >
  <a class="link-primary" @click="nextPage" v-if="products.nextPage"> Next </a>

  <ul class="list-group list">
    <li v-for="product in activeProducts" :key="product.id">
      <Product :product="product" />
    </li>
  </ul>
</template>
<style scoped>
ul {
  list-style-type: none;
}
</style>
<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useProductStore } from "@/store/product";
import Product from "@/components/product/product_table/Product.vue";

export default defineComponent({
  components: {
    Product,
  },
  setup() {
    const products = useProductStore();
    products.fetchProducts();

    const activeProducts = computed(() => products.products);
    function nextPage() {
      products.getNextPage();
    }

    function prevPage() {
      products.getPrevPage();
    }

    return { activeProducts, products, nextPage, prevPage };
  },
});
</script>
