
import { defineComponent, computed } from "vue";
import { useCartStore } from "@/store/cart";
import CheckoutTable from "@/components/cart/CheckoutTable.vue";
import GeneralAlert from "@/components/alerts/GeneralAlert.vue";

export default defineComponent({
  components: {
    CheckoutTable,
    GeneralAlert,
  },
  setup() {
    const cart = useCartStore();
    const items = computed(() => cart.items);
    const total = computed(() => cart.subtotal);

    return { items, total };
  },
});
